<template>
  <div>
    <h1 class="-mt-3">LOKALITA NÁKLADOVÉHO NÁDRAŽÍ ŽIŽKOV</h1>
    <div class="grid lg:grid-cols-2 gap-8">
      <div>
        <lightgallery class="pointer-children">
          <img src="../assets/images/03-lokalita/MUSA-NNZ-12.jpg" alt="">
          <img class="mt-8" src="../assets/images/03-lokalita/MUSA-NNZ-13.jpg" alt="">
        </lightgallery>
        <div></div>
      </div>
      <div>
        <p>Nákladové nádraží Žižkov je&nbsp;jedním ze tří nákladových nádraží (dalšími jsou nádraží v&nbsp;Bubnech a&nbsp;na Smíchově), která dříve zásobovala centrum Prahy a&nbsp;jeho průmyslové areály. Dnes však už ani jedno z&nbsp;nich není v&nbsp;provozu, a&nbsp;tak bývalé drážní plochy čeká proměna v&nbsp;plnohodnotné součásti města.</p>
        <p class="mt-4">Území žižkovského nádraží má díky své blízkosti k&nbsp;centru vysoký rozvojový potenciál, prozatím však jeho využití bránila stavební uzávěra vymezená ve stále platném Územním plánu hl.&nbsp;m.&nbsp;Prahy z&nbsp;roku 1999. Aby bylo možné zahájit přeměnu celé plochy bývalého nádraží, je nejprve potřeba provést změnu územního plánu. Tento proces začal již v&nbsp;roce 2009, dnes se&nbsp;však i&nbsp;díky podkladové studii (Urbanistická studie s&nbsp;prvky regulačního plánu NNŽ), kterou zpracoval IPR&nbsp;Praha a&nbsp;projednává ji s&nbsp;investory, politiky i&nbsp;občany Prahy 3, blížíme ke schválení potřebné změny.</p>
        <p class="mt-4">Výstava „Žižkovské nádraží: druhý život?“ v&nbsp;Centru architektury a&nbsp;městského plánování podrobně představila aktuální informace o&nbsp;budoucnosti 60hektarového území bývalého nákladového nádraží a&nbsp;přilehlého okolí, kde se už plánuje či probíhá realizace velkých veřejných i&nbsp;soukromých investic. Těžištěm území je&nbsp;bezpochyby vlastní prostor bývalého nákladového nádraží s&nbsp;hlavní budovou, která byla v&nbsp;roce 2013 prohlášena nemovitou kulturní památkou a&nbsp;v&nbsp;rámci rozvoje území by měla být společnými silami několika veřejných institucí (MHMP, MČ Praha&nbsp;3, Ministerstvo kultury, NFA) a&nbsp;developera Sekyra Group přeměněna na společensko-kulturní centrum. Dalšími velkými veřejnými investicemi jsou například nové tramvajové trati, nové uliční propojení s&nbsp;budoucím Městským okruhem – Jarovská třída, rekonstrukce ulic Jana Želivského a&nbsp;Malešické a&nbsp;také výstavba dvou základních škol. Největšími soukromými investicemi v&nbsp;území jsou pak bytové projekty Parková čtvrť a&nbsp;Žižkov City spolu s&nbsp;dalšími developerskými záměry (Rezidence nádraží Žižkov, U&nbsp;Staré cihelny, Nagano Park, Obytná čtvrť Jarov a&nbsp;další).</p>
        <address class="text-sm">Text použit s&nbsp;laskavým svolením z&nbsp;katalogu výstavy Žižkovské&nbsp;nádraží: Druhý&nbsp;život?<br>CAMP/ IPR&nbsp;Praha/ 21.&nbsp;9.&nbsp;2021&nbsp;–&nbsp;9.&nbsp;1.&nbsp;2022.</address>
      </div>
    </div>
    <lightgallery class="grid lg:grid-cols-4 gap-8 mt-12 pointer-children">
      <img src="../assets/images/03-lokalita/MUSA-NNZ-8.jpg" alt="">
      <img src="../assets/images/03-lokalita/MUSA-NNZ-9.jpg" alt="">
      <img src="../assets/images/03-lokalita/MUSA-NNZ-10.jpg" alt="">
      <img src="../assets/images/03-lokalita/MUSA-NNZ-11.jpg" alt="">
    </lightgallery>
    <div class="grid lg:grid-cols-2 lg:grid-rows-2 lg:grid-flow-row mt-12 gap-8">
      <div class="mb-6">
        <h1>REVITALIZACE</h1>
        <p>Revitalizace významné rozvojové lokality se silnou historickou industriální stopou evropského významu.</p>
      </div>
      <div class="mb-6">
        <h1>LOKALITA</h1>
        <p>Lokalita, která se stane přirozeným kulturním a&nbsp;společenským centrem pro cca 400 000 obyvatel Prahy a&nbsp;především MČ Praha 3.</p>
      </div>
      <div class="mb-6"> 
        <h1>TRANSFORMACE</h1>
        <p>Transformace kulturní památky evropského významu.</p>
      </div>
      <div class="mb-6">
        <h1>ROZVOJ</h1>
        <p>Podpora ze strany investorů, kteří investují do rozvoje lokality – donátoři nové středoevropské instituce.</p>
      </div>
    </div>
  </div>
</template>

<script>
import jQuery from "jquery";
const $ = jQuery;
window.$ = $;

import Lightgallery from 'lightgallery/vue';

export default {
  name: 'Lokalita',
  components: {
    Lightgallery,
  },
  mounted() {
    window.scrollTo({ top: 0});
  },
}
</script>
